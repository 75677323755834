<template>
      <div class="head">
        <h1>Kitchen Studios</h1>
      </div>


      <div class="body">
        <h2>It's a Dreams Come True</h2>
        <h2>Coming Soon</h2>
        <div class="VideoYT">
          <YouTube
          src="https://www.youtube.com/watch?v=GN0f6PwkJ48"
          height="60"
          width="90"
          @ready="onReady"
          ref="youtube" />
        </div>
      </div>
      <img src="/files/PromoLogo.jpg"/>
        <div class="footer">
          2023 (c)
        </div>
</template>

<script>
import { defineComponent } from 'vue'
import YouTube from 'vue3-youtube'

export default defineComponent({
    components: { YouTube },
    methods: {
        onReady() {
            this.$refs.youtube.playVideo()
        },
    },
})
</script>

<style>
.VideoYT {
  position: absolute;
  right: 50%;
}
.body{
  display: block;
  position: relative;
  top: 25px;
}
.head {
  display: block;
  background-color: black;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 30px;
  width: 100%;
  border-top: 1px solid white;
  text-align: center;
}
.footer {
  display: block;
  background-color: grey;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 20px;
  width: 100%;
  border-top: 1px solid black;
  text-align: right;
}
h1 {
  color: #ffab00;
  font-family: Garamond, serif;
  font-size: 15px
}
h2 {
  font-size: 16px;
  color: #gold;
  font-family: 'Courier New', monospace;
  text-align: center;
}
h3 {
  font-size: 16px;
  color: #black;
  font-family: Georgia, serif;
  text-align: center;
}
h5 {
  color: white;
  font-family: Garamond, serif;
  text-align: right;
}
h6 {
  padding-bottom: 20cm;
}
</style>
